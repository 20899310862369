import React, { useState } from "react";

import TabControls from "./TabControls";
import Content from "./Content";

export default function CenterCard() {
  const [currentTab, setCurrentTab] = useState("Home");
  const [visible, setVisible] = useState(true);

  if (!visible)
    return (
      <div
        className="w-full h-full flex items-center justify-center"
        onClick={() => {
          setVisible(true);
        }}
      >
        <h1 className="text-slate-400 font-semibold p-4 inline-block border border-slate-400 border-dashed rounded-md cursor-pointer select-none">
          Take me out of purgatory
        </h1>
      </div>
    );

  return (
    <div className="bg-white overflow-hidden shadow sm:rounded-lg h-[75%] overflow-y-scroll sm:h-fit w-full sm:w-fit sm:aspect-[3/2] rounded-lg">
      <div className="max-h-full max-w-4xl w-full sm:w-[700px] flex flex-col relative">
        <TabControls
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setVisible={setVisible}
        />
        <Content currentTab={currentTab} />
      </div>
    </div>
  );
}
