import React, { useState } from "react";

import FancyBackground from "./FancyBackground"
import CenterCard from "./CenterCard"

import './App.css';

function App() {

  return (<div className="w-full h-full isolate overflow-hidden bg-gray-900 flex items-center justify-center relative sm:p-12 p-8 overscroll-contain">
    <FancyBackground />
    <CenterCard />
  </div>);
}

export default App;
