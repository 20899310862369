export default function TabControls({ currentTab, setCurrentTab, setVisible }) {
  const tabClasses =
    "data-[a=true]:text-gray-700 data-[a=true]:bg-gray-300 data-[a=false]:hover:text-gray-600 text-gray-500 font-semibold rounded-md px-2 py-1 transition";

  return (
    <div className="flex flex-col gap-1 bg-gray-200 p-3 sticky top-0 w-full border-b border-b-gray-300 shadow">
      <div className="flex flex-row gap-2">
        <div
          className="block w-3 h-3 rounded-full bg-red-500 hover:bg-red-600 transition"
          onClick={() => {
            setVisible(false);
          }}
        />
        <div className="block w-3 h-3 rounded-full bg-yellow-500 hover:bg-yellow-600 transition" />
        <div className="block w-3 h-3 rounded-full bg-green-500 hover:bg-green-600 transition" />
      </div>
      <div className="flex flex-row overflow-x-scroll custom-no-scrollbar">
        <p
          data-a={currentTab == "Home"}
          className={tabClasses}
          onClick={() => setCurrentTab("Home")}
        >
          Home
        </p>
        <p
          data-a={currentTab == "Contact"}
          className={tabClasses}
          onClick={() => setCurrentTab("Contact")}
        >
          Contact
        </p>
        <p
          data-a={currentTab == "Projects"}
          className={tabClasses}
          onClick={() => setCurrentTab("Projects")}
        >
          Projects
        </p>
        <p
          data-a={currentTab == "Publications"}
          className={tabClasses}
          onClick={() => setCurrentTab("Publications")}
        >
          Publications
        </p>
      </div>
    </div>
  );
}
